import './EditUser.css';
import {useEffect, useState} from "react";
import {ActivityIndicator, Input, PasswordInput, PrimaryButton, Switch} from "../../../../components";
import Select from "react-select";
import AppService from "../../../../services/AppService";
import ServerService from "../../../../services/ServerService";
import Close from "@mui/icons-material/Close";
import UserService from "../../../../services/UserService";
import {useParams} from "react-router-dom";


function EditUser() {
    const [user, setUser] = useState({
        email: '',
        password: '',
        fname: '',
        lname: '',
        phone: '',
        numberOfStreams: 1,
        numberOfRestreams: 1,
        emailError: false,
        passwordError: false,
        fnameError: false,
        lnameError: false,
        phoneError: false,
        numberOfStreamsError: false,
        numberOfRestreamsError: false,
        streamError: false
    });
    const [streams, setStreams] = useState([]);
    const [removedStreamsId, setRemovedStreamsId] = useState([]);
    const [stream, setStream] = useState({
        name: '',
        srtPort: 1,
        latency: '',
        selectedServer: {},
        selectedApp: {},
        stream: '',
        nameError: false,
        srtPortError: false,
        latencyError: false,
        serverError: false,
        appError: false,
        streamError: false
    });
    const [servers, setServers] = useState([]);
    const [apps, setApps] = useState([]);
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isNewData, setIsNewData] = useState(false);
    const [errorText, setErrorText] = useState('');

    const {userId} = useParams();
    const appService = new AppService();
    const serverService = new ServerService();
    const userService = new UserService();

    useEffect(() => {
        const fetchData = async () => {
            const userResult = await userService.getUser(userId);
            const appsResult = await appService.getApps();
            const serversResult = await serverService.getServers();

            if (
                !userResult || !userResult.status ||
                !appsResult || !appsResult.status ||
                !serversResult || !serversResult.status
            ) return;

            const apps = appsResult.data.map((app) => ({
                label: app.app,
                value: app._id,
                serverId: app.serverId._id
            }));
            const servers = serversResult.data.map((server) => ({
                label: server.origin,
                value: server._id
            }));

            const newStreams = userResult.data.streamsId.map((newStreamItem) => {
                return {
                    ...newStreamItem,
                    app: {
                        label: newStreamItem.app.app,
                        value: newStreamItem.app._id
                    },
                    server: {
                        label: newStreamItem.serverId.origin,
                        value: newStreamItem.serverId._id
                    }
                }
            });

            setUser({
                ...userResult.data,
                password: '         ',
                emailError: false,
                passwordError: false,
                fnameError: false,
                lnameError: false,
                phoneError: false,
                numberOfStreamsError: false,
                numberOfRestreamsError: false,
                streamError: false
            });
            setStreams([
                ...newStreams
            ]);
            setApps(apps);
            setServers(servers);
        }

        fetchData();
    }, []);

    const validateStreamForm = () => {
        const {name, srtPort, stream: streamName, selectedServer, selectedApp} = stream;
        let state = true;
        if (!name) {
            state = false;
            setStream((oldState) => ({
                ...oldState,
                nameError: true
            }));
        }

        if (!srtPort) {
            state = false;
            setStream((oldState) => ({
                ...oldState,
                srtPortError: true
            }));
        }

        if (!streamName) {
            state = false;
            setStream((oldState) => ({
                ...oldState,
                streamError: true
            }));
        }

        if (Object.keys(selectedServer).length === 0) {
            state = false;
            setStream((oldState) => ({
                ...oldState,
                serverError: true
            }));
        }

        if (Object.keys(selectedApp).length === 0) {
            state = false;
            setStream((oldState) => ({
                ...oldState,
                appError: true
            }));
        }

        return state;
    }

    const addStream = () => {
        const {name, srtPort, latency, selectedServer, selectedApp, stream: streamName} = stream;
        const dublicateStream = streams.find((streamItem) => streamItem.name === name);

        if (dublicateStream && dublicateStream !== -1) return;
        if (!validateStreamForm()) return;

        setStreams((oldState) => ([
            ...oldState,
            {
                name,
                srtPort,
                latency,
                server: selectedServer,
                app: selectedApp,
                stream: streamName
            }
        ]));

        setStream({
            name: '',
            srtPort: 1,
            latency: '',
            selectedServer: {},
            selectedApp: {},
            stream: '',
            nameError: false,
            srtPortError: false,
            latencyError: false,
            serverError: false,
            appError: false,
            streamError: false
        });
    }

    const removeStream = (removedStream) => {
        const newStreams = streams.filter((streamItem) => streamItem.name !== removedStream.name && streamItem['_id'] !== removedStream['_id']);
        console.log(newStreams, removedStream);
        setStreams(newStreams && newStreams.length > 0 ? newStreams : []);
        if (removedStream['_id']) setRemovedStreamsId((oldState) => [...oldState, removedStream['_id']]);
    }

    const validateUserForm = () => {
        const {
            email,
            password,
            fname,
            lname,
            phone,
            numberOfStreams,
            numberOfRestreams
        } = user;
        let state = true;

        if (!email || email.length <= 3) {
            state = false;
            setUser((oldState) => ({
                ...oldState,
                emailError: true
            }));
        }

        if (password && password.length <= 8) {
            state = false;
            setUser((oldState) => ({
                ...oldState,
                passwordError: true
            }));
        }

        if (!fname) {
            state = false;
            setUser((oldState) => ({
                ...oldState,
                fnameError: true
            }));
        }

        if (!lname) {
            state = false;
            setUser((oldState) => ({
                ...oldState,
                lnameError: true
            }));
        }

        if (!phone) {
            state = false;
            setUser((oldState) => ({
                ...oldState,
                phoneError: true
            }));
        }

        if (numberOfStreams <= 0) {
            state = false;
            setUser((oldState) => ({
                ...oldState,
                numberOfStreamsError: true
            }));
        }

        if (numberOfRestreams <= 0) {
            state = false;
            setUser((oldState) => ({
                ...oldState,
                numberOfRestreamsError: true
            }));
        }

        if (streams.length === 0) {
            state = false;
            setUser((oldState) => ({
                ...oldState,
                streamError: true
            }));
        }

        return state;
    }

    const updateUser = async () => {
        if (!validateUserForm()) return;
        setLoading(true);

        setUser((oldState) => ({
            ...oldState,
            emailError: false,
            passwordError: false,
            fnameError: false,
            lnameError: false,
            phoneError: false,
            numberOfStreamsError: false,
            numberOfRestreamsError: false,
            streamError: false
        }));

        const newStreams = streams.map((streamItem) => ({
            name: streamItem.name,
            srtPort: streamItem.srtPort,
            latency: streamItem.latency,
            serverId: streamItem.server.value,
            app: streamItem.app.value,
            stream: streamItem.stream,
            _id: streamItem._id

        }));

        const data = {
            email: user.email,
            password: user.password,
            fname: user.fname,
            lname: user.lname,
            phone: user.phone,
            numberOfStreams: user.numberOfStreams,
            numberOfRestreams: user.numberOfRestreams,
            isAvaliable: user.isAvaliable,
            id: user._id,
            streams: newStreams,
            removedStreamsId
        }

        const result = await userService.updateUser(data);

        setTimeout(() => {
            setLoading(false);
            if (!result || !result.status) {
                setServerError(true);
                if (result.errors && result.errors.length > 0) setErrorText(result.errors[0]);
                return;
            }
            setIsEdit(false);
            window.location = '/admin/users';
        }, 500);
    }

    if (loading) {
        return (
            <div className={'add-user__action-wrapper'}>
                <ActivityIndicator/>
            </div>
        );
    }

    return (
        <form className={'add-user__wrapper'}>
            <div className={'add-user__header'}>
                <h2>Edit user</h2>
                <div>
                    <PrimaryButton text={isEdit ? 'cancel' : 'edit'} onclick={(e) => {
                        setIsEdit((oldState) => !oldState);
                        e.preventDefault();
                    }}/>
                    <PrimaryButton
                        text={'save'}
                        className={isNewData ? 'save' : ''}
                        onclick={(e) => {
                            updateUser();
                            e.preventDefault();
                        }}
                    />
                </div>
            </div>
            {
                user.streamError && (
                    <div className={'add-user__error'}>
                        <b>Add at least 1 stream to the user</b>
                    </div>
                )
            }
            {
                serverError && (
                    <div className={'add-user__error'}>
                        <b>{errorText ? errorText : 'Server error! Please try again'}</b>
                    </div>
                )
            }
            <div
                className={'add-user__main'}
                style={isEdit ? {} : {pointerEvents: 'none', opacity: 0.4}}
            >
                <Input
                    typeTitle={'Name'}
                    text={user.fname}
                    error={user.fnameError}
                    oninput={(e) => {
                        setIsNewData(true);
                        setUser((oldState) => ({
                            ...oldState,
                            fname: e.target.value
                        }))
                    }}
                />
                <Input
                    typeTitle={'Surname'}
                    text={user.lname}
                    error={user.lnameError}
                    oninput={(e) => {
                        setIsNewData(true);
                        setUser((oldState) => ({
                            ...oldState,
                            lname: e.target.value
                        }))
                    }}
                />
                <Input
                    typeTitle={'Email'}
                    text={user.email}
                    error={user.emailError}
                    type={'email'}
                    oninput={(e) => {
                        setIsNewData(true);
                        setUser((oldState) => ({
                            ...oldState,
                            email: e.target.value
                        }))
                    }}
                />
                <PasswordInput
                    typeTitle={'Password'}
                    text={user.password}
                    type={'password'}
                    error={user.passwordError}
                    autocomplete={'on'}
                    placeholder={'Min length is 9 letters'}
                    oninput={(e) => {
                        setIsNewData(true);
                        setUser((oldState) => ({
                            ...oldState,
                            password: e.target.value
                        }))
                    }}
                />
                <Input
                    typeTitle={'Phone'}
                    text={user.phone}
                    error={user.phoneError}
                    type={'tel'}
                    oninput={(e) => {
                        setIsNewData(true);
                        setUser((oldState) => ({
                            ...oldState,
                            phone: e.target.value
                        }))
                    }}
                />
                <Input
                    typeTitle={'Number of streams'}
                    text={user.numberOfStreams}
                    error={user.numberOfStreamsError}
                    type={'number'}
                    min={1}
                    oninput={(e) => {
                        const value = parseInt(e.target.value);
                        if (value <= 0) return;
                        setIsNewData(true);

                        setUser((oldState) => ({
                            ...oldState,
                            numberOfStreams: value
                        }));
                    }}
                />
                <Input
                    typeTitle={'Number of restreams'}
                    text={user.numberOfRestreams}
                    error={user.numberOfRestreamsError}
                    type={'number'}
                    min={1}
                    oninput={(e) => {
                        const value = parseInt(e.target.value);
                        if (value <= 0) return;
                        setIsNewData(true);

                        setUser((oldState) => ({
                            ...oldState,
                            numberOfRestreams: value
                        }));
                    }}
                />
                <Switch text={'Off/On'} checked={user.isAvaliable} onchange={() => {
                    setIsNewData(true);
                    setUser((oldState) => ({
                        ...oldState,
                        isAvaliable: !oldState.isAvaliable
                    }));
                }}/>
            </div>
            <hr/>
            <b className={'add-user__streams-title'}>Streams</b>
            <table className={'add-user__streams'} style={isEdit ? {} : {pointerEvents: 'none', opacity: 0.4}}>
                <thead>
                <tr>
                    <th>Name of stream</th>
                    <th>Server</th>
                    <th>App</th>
                    <th>Stream name</th>
                    <th>SRT port</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {streams.length > 0 ? streams.map((streamItem) => (
                    <tr key={streamItem.name}>
                        <td>{streamItem.name}</td>
                        <td>{streamItem.server.label}</td>
                        <td>{streamItem.app.label}</td>
                        <td>{streamItem.stream}</td>
                        <td>{streamItem.srtPort}</td>
                        <td>
                            <Close className={'add-user__streams-actions'} onClick={() => {
                                removeStream(streamItem);
                                setIsNewData(true);
                            }}/>
                        </td>
                    </tr>
                )) : (
                    <tr>
                        <td colSpan={6}>The list is empty</td>
                    </tr>
                )}
                </tbody>
            </table>
            {user.numberOfStreams > streams.length && (
                <div className={'add-user__stream-wrapper'} style={isEdit ? {} : {pointerEvents: 'none', opacity: 0.4}}>
                    <Input
                        typeTitle={'Name of stream'}
                        text={stream.name}
                        error={stream.nameError}
                        oninput={(e) => setStream((oldState) => ({
                            ...oldState,
                            name: e.target.value
                        }))}
                    />
                    <div className={'add-user__stream'}>
                        <div className={'user__select'}>
                            <label>Server</label>
                            <Select
                                options={servers}
                                value={stream.selectedServer}
                                onChange={(selectedOption) => {
                                    setStream((oldState) => ({
                                        ...oldState,
                                        selectedServer: selectedOption
                                    }));
                                }}
                            />
                        </div>
                        <div className={'user__select'}>
                            <label>App</label>
                            <Select
                                isDisabled={!stream.selectedServer.value}
                                options={
                                    apps.filter((app) => app.serverId === stream.selectedServer.value)
                                }
                                value={stream.selectedApp}
                                onChange={(selectedOption) => {
                                    setStream((oldState) => ({
                                        ...oldState,
                                        selectedApp: selectedOption
                                    }));
                                }}
                            />
                        </div>
                        <Input
                            typeTitle={'Stream name'}
                            text={stream.stream}
                            error={stream.streamError}
                            oninput={(e) => setStream((oldState) => ({
                                ...oldState,
                                stream: e.target.value
                            }))}
                        />
                        <Input
                            typeTitle={'SRT port'}
                            text={stream.srtPort}
                            error={stream.srtPortError}
                            type={'number'}
                            min={1}
                            oninput={(e) => {
                                const value = parseInt(e.target.value);
                                if (value <= 0) return;
                                setStream((oldState) => ({
                                    ...oldState,
                                    srtPort: value
                                }));
                            }}
                        />
                        <Input
                            typeTitle={'Latency'}
                            text={stream.latency}
                            placeholder={'optional'}
                            oninput={(e) => setStream((oldState) => ({
                                ...oldState,
                                latency: e.target.value
                            }))}
                        />
                        <Input
                            typeTitle={'StreamID'}
                            text={stream.streamId}
                            placeholder={'optional'}
                            oninput={(e) => setStream((oldState) => ({
                                ...oldState,
                                streamId: e.target.value
                            }))}
                        />
                        <PrimaryButton text={'Add stream'} onclick={(e) => {
                            addStream(stream);
                            setIsNewData(true);
                            e.preventDefault();
                        }}/>
                    </div>
                </div>
            )}
        </form>
    );
}

export default EditUser;
