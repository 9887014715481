import React from 'react';
import './Navigation.css';
import logo from '../../../../assets/images/logo.png';
import {Link, useParams} from 'react-router-dom';


function Navigation(props) {
    const streams = props.streams || [];
    const {activeStream} = useParams();

    return (
        <div className={'navigation'}>
            <img className={'navigation__logo'} src={logo}/>
            <ul>
                {streams.length > 0 ?
                    streams.map((stream) => (
                        <li key={stream._id}>
                            <Link to={`/streams/${stream._id}`}
                                  className={activeStream == stream._id ? 'active' : null}>{stream.name}</Link>
                        </li>
                    ))
                    : (
                        <li>No streams!</li>
                    )}
            </ul>
        </div>
    );
}

export default Navigation;
