import './AppsListItem.css';
import Close from '@mui/icons-material/Close';
import Modal from "react-modal";
import {PrimaryButton} from "../../../../components";
import {useState} from 'react';


function AppsListItem(props) {
    const {app, removeApp} = props;
    const [isRemove, setIsRemove] = useState(false);

    if (isRemove) {
        return (
            <Modal
                isOpen={isRemove}
                onRequestClose={() => {
                    setIsRemove(false);
                }}
                className="restream__remove-modal"
                overlayClassName="restream__remove-modal-overlay"
            >
                <div className={'restream__modal-msg'}>
                    <b>Do you really want to delete this server?</b>
                </div>
                <div className={'restream__remove-buttons'}>
                    <PrimaryButton text={'No'} type={'white'} onclick={() => setIsRemove(false)}/>
                    <PrimaryButton text={'Yes'} onclick={() => removeApp(app._id)}/>
                </div>
            </Modal>
        );
    }

    return (
        <tr className={'apps__item'}>
            <td>{app.app}</td>
            <td>{app.appLogin}</td>
            <td>{app.appPassword}</td>
            <td>{app.serverId.origin}</td>
            <td>
                <Close className={'apps__item-action'} onClick={() => {
                    setIsRemove(true);
                }}/>
            </td>
        </tr>
    );
}

export default AppsListItem;
